.topbar {
  height: 50px;
  width: calc(100% - 20px);
  padding: 10px;
  margin: 0 10px 0px 10px;
  border-bottom: 1px solid var(--dark-background-50);
  display: grid;
  align-content: center;
  justify-content: end;
  font-size: small;
}

.right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.user {
  border-right: 1px solid var(--dark-background-50);
  margin-right: 5px;
  padding-right: 5px;
}