body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

:root {
  --dark-background: #1E1E1E; 
  --dark-background-50: #616161;  
  --dark-text: #fff;
  --orange: #F47635;
  --menu-hover-background: #F47635;
  --menu-active-background: #f6915d;
  --light-grey-background: #F5F5F5;
}