.layout {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}

.right {
  width: 100%;
}

.main {
  height: calc(100vh - 50px);
  overflow: scroll;
  width: 100%;
  padding: 0 20px;
}