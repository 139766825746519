.container {
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.message {
  height: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.message button {
  margin-top: 10px;
}